import { Box, Button, Divider, TextField } from "@mui/material";
import styles from "./style.module.scss";
import { Controller, useForm, useWatch } from "react-hook-form";
import FormReactSelect from "../../../../components/FormSelect";
import { iataCodes, sources } from "../../../../consts/flights";
import FormReactAsyncSelect from "../../../../components/FormSelect/AsyncSelect";
import { promiseCity } from "../../../../utils/getCities";
import { leadTypesArray } from "../../../../consts/lead";
import FRow from "../../../../components/FRow";
import { useParams } from "react-router-dom";
import useOrder from "../../../../hooks/useOrder";

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: "auto",
    background: "#fff",
  }),
};

const QuickLeadForm = ({ setLeadFormOpen }) => {
  const { control, setValue, handleSubmit, reset } = useForm();
  const { id } = useParams();

  const location = useWatch({
    control,
    name: "lead.location",
  });

  const { isConfirmed, handleCreateChatOrder } = useOrder();

  const onSubmit = (values) => {
    setLeadFormOpen(false);
    reset({});
    handleCreateChatOrder({
      ...values,
      tg_chat_id: id,
    });
  };

  return (
    <form className={styles.lead_form} onSubmit={handleSubmit(onSubmit)}>
      <FRow label="Lead type" required>
        <FormReactSelect
          name="lead.type"
          placeholder="Select lead type"
          control={control}
          options={leadTypesArray}
          required
          isDisabled={isConfirmed}
        />
      </FRow>
      <FRow label="Source" required>
        <FormReactSelect
          name="lead.source"
          placeholder="Select lead source"
          control={control}
          options={sources}
          required
          isDisabled={isConfirmed}
        />
      </FRow>

      <FRow label="First name" required>
        <Controller
          name="lead.first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter first name"
              className={styles.input}
              required
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>
      <FRow label="Last name">
        <Controller
          name="lead.last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter last name"
              className={styles.input}
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>
      <FRow label="Middle name">
        <Controller
          name="lead.sur_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter middle name"
              className={styles.input}
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>
      <FRow label="Customer location" required>
        <FormReactAsyncSelect
          styles={customStyles}
          placeholder="Select location"
          value={{ value: location, key: location }}
          className={styles.select}
          defaultOptions={iataCodes}
          getOptionLabel={(opt) => opt.label || opt.key}
          getOptionValue={(opt) => opt.value || opt.key}
          control={control}
          name={"lead.location"}
          loadOptions={promiseCity}
          isDisabled={isConfirmed}
          customOnChange={(e) => {
            setValue("lead.location", e.value);
          }}
          required
        />
      </FRow>
      <FRow label="Phone number" required>
        <Controller
          name="lead.phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter phone number"
              className={styles.input}
              required
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>
      <FRow label="Reserve phone number">
        <Controller
          name="lead.reserve_phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter reserve number"
              className={styles.input}
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>

      <FRow label="Email">
        <Controller
          type="email"
          name="lead.email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter email"
              className={styles.input}
              type="email"
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>
      <FRow label="Telegram" required>
        <Controller
          name="lead.telegram"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter Telegram username"
              className={styles.input}
              required
              disabled={isConfirmed}
            />
          )}
        />
      </FRow>
      {/* <Divider /> */}
      <div className={styles.footer}>
        <div className={styles.btn_group}>
          <Button
            className={styles.cancel_button}
            onClick={() => setLeadFormOpen(false)}
          >
            Cancel
          </Button>
          <Button type="submit" className={styles.save_button}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default QuickLeadForm;
