import React from "react";
import styles from "./style.module.scss";
import moment from "moment";

const ChatMessage = ({
  sender,
  time,
  content,
  file,
  out,
  data,
  message,
  nextMessage,
  prevMessage,
}) => {
  const isOther = !out;
  const formattedDate = moment.unix(time).format("HH:mm");
  const otherUserName = data?.Users?.find((user) => !user?.Self)?.Username;
  const URL = message?.Media?.Webpage?.URL;
  const replyMsg = data?.find(
    (item) => item?.ID === message?.ReplyTo?.ReplyToMsgID
  );

  const shouldShowHeader =
    nextMessage?.PeerID?.UserID !== prevMessage?.PeerID?.UserID;

  return (
    <div
      className={`${styles.message} ${isOther ? styles.other : styles.self}`}
    >
      {/* {shouldShowHeader && ( */}
      <div className={styles.header}>
        <span className={styles.sender}>{isOther && otherUserName}</span>
        <span className={styles.time}>{formattedDate || "--:--"}</span>
      </div>
      {/* )} */}
      <div className={styles.body}>
        {content && !URL ? (
          <p className={styles.content}>
            {message?.ReplyTo?.ReplyToMsgID && (
              <span className={styles.reply_txt}>{replyMsg?.Message}</span>
            )}
            {content}
          </p>
        ) : null}
        {URL && (
          <a
            href={URL}
            target="_blank"
            rel="noreferrer"
            className={styles.url_content}
          >
            {URL}
          </a>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
