export const messages = [
  {
    sender: "Anita Cruz",
    time: "Thursday 12:14PM",
    content:
      "Great! We've drafted an outline here. Let me know if you have any questions!",
    senderType: "self",
  },
  {
    sender: "Anita Cruz",
    time: "Thursday 12:14PM",
    content: "Dubai - Tashkent.pdf (800 KB)",
    file: true,
    senderType: "self",
  },
  {
    sender: "O.Qodirjon",
    time: "Thursday 12:29PM",
    content:
      "I'll have a more thorough read and get back to you by tomorrow. Is that okay?",
    senderType: "other",
  },
  {
    sender: "O.Qodirjon",
    time: "10 mins ago",
    content:
      "Hey Anita, I've had a read through and made some notes: https://docs.google.com/docu...",
    senderType: "other",
  },
  {
    sender: "Anita Cruz",
    time: "Just now",
    content: "Thank you for the quick turnaround. Looking now.",
    senderType: "self",
  },
  { sender: "Anita Cruz", time: "Just now", content: "••", senderType: "self" },
];

export const statusOptions = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];
