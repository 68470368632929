import { Button, Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ChatTools from "../Tool";
import { UploadIcon } from "../../../../assets/icons";
import AddIcon from "@mui/icons-material/Add";
import ImagePreview from "../ImagesPreview";

export default function ChatFileUpload({
  open,
  setOpen,
  uploadedFiles,
  handleRemoveFile,
  setUploadedFiles,
}) {
  const handleClose = () => {
    setOpen(false);
    setUploadedFiles([]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <UploadIcon />
          </div>
          <div className={styles.content}>
            <h3>Выбрать</h3>
            <p>Выбрана 5 изображения</p>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.previews}>
            {uploadedFiles?.map((file, index) => (
              <ImagePreview
                key={index}
                file={file}
                onRemove={handleRemoveFile}
              />
            ))}
          </div>
          <ChatTools isUpload />
        </div>
        <div className={styles.footer}>
          <div>
            <Button
              startIcon={<AddIcon />}
              className={styles.cancel_button}
              onClick={handleClose}
            >
              Upload
            </Button>
          </div>
          <div className={styles.btn_group}>
            <Button className={styles.cancel_button} onClick={handleClose}>
              Cancel
            </Button>
            <Button className={styles.save_button}>Save</Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
