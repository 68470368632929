import React, { useState } from "react";
import styles from "./styles.module.scss";

const ImagePreview = ({ file, onRemove }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className={styles.previewItem}>
      <img
        src={URL.createObjectURL(file)}
        alt="preview"
        className={styles.previewImage}
        onLoad={handleImageLoad}
        style={{ display: loading ? "none" : "block" }}
      />
      {loading && <div className={styles.loadingSpinner}>Loading...</div>}
      <button className={styles.closeButton} onClick={() => onRemove(file)}>
        ×
      </button>
    </div>
  );
};

export default ImagePreview;
