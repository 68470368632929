import { Button, Menu } from "@mui/material";
import Fade from "@mui/material/Fade";
import styles from "./style.module.scss";
import { useState } from "react";
import "./style.scss";
import FRow from "../../../../../components/FRow";
import TSelect from "../../../../../components/Select";
import TDatePicker from "../../../../../components/Datepicker";

export default function SessionFilterMenu({ open, onClose, anchorEl }) {
  const [loader, setLoader] = useState(false);

  return (
    <Menu
      id="note-menu"
      className={styles.menu}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <div className={styles.header}>
        <div className={styles.content}>
          <FRow label="Status agent">
            <TSelect
              isMulti={true}
              components={{
                // DropdownIndicator: PersonRoundedIcon,
                IndicatorSeparator: null,
              }}
              // loadOptions={getAgentsByName}
              // defaultOptions={users?.users?.rows}
              getOptionLabel={(opt) => opt.first_name}
              // onChange={onChangeAgentsFilter}
              getOptionValue={(opt) => opt.id}
              placeholder="Select status"
            />
          </FRow>
          <FRow label="Month/Year">
            <TDatePicker
              placeholder="Select date"
              // shouldDisableDate={(dt) => {
              //   return new Date() < dt;
              // }}
              placement="auto"
              preventOverflow
              className={styles.date_picker}
            />
          </FRow>
        </div>
      </div>
      <div className={styles.body}>
        <Button className={styles.cancel_button}>Cancel</Button>
        <Button className={styles.save_button}>Save</Button>
      </div>
    </Menu>
  );
}
