import React from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Button,
} from "@mui/material";
import styles from "./style.module.scss";
import {
  AttachmentIcon,
  AudioTrashIcon,
  ChatSendIcon,
  MicroPhoneIcon,
  RecordingIcon,
} from "../../../../assets/icons";
import EmojiPicker from "../EmojiPicker";
import CustomAudioPlayer from "../AudioMessage";
import useTool from "./useTool";
import { useParams } from "react-router-dom";
import useChats from "../../../../hooks/api/useChatsAPI";

const ChatTools = ({
  onUploadOpen,
  isUpload = false,
  handleFileChange,
  chatRefetch,
  messageRefetch,
  handleScrollDown,
}) => {
  const { id, hashId } = useParams();

  const {
    recording,
    audioURL,
    message,
    timer,
    startRecording,
    stopRecording,
    handleDeleteAudio,
    formatTime,
    handleEmojiSelect,
    setMessage,
  } = useTool();

  const isRecording = !audioURL && recording;
  const isUrlValid = audioURL && !recording;
  const invalidRecord = !audioURL && !recording;

  const clickHandler = () => {
    const handler = document.getElementById("inputFile");
    handler.click();
  };

  const { sendMessage, sendMessageLoading } = useChats();

  const handleSendMessage = () => {
    const payload = {
      id: id,
      hashId: hashId,
      message: message,
    };
    sendMessage(payload, {
      onSuccess: () => {
        chatRefetch();
        messageRefetch();
        setMessage("");
        handleScrollDown();
      },
    });
  };

  return (
    <>
      {invalidRecord && (
        <TextField
          variant="outlined"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton onClick={clickHandler}>
                  <input
                    id="inputFile"
                    type="file"
                    multiple
                    hidden
                    onChange={handleFileChange}
                  />
                  <AttachmentIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment>
                <EmojiPicker onSelect={handleEmojiSelect} />
                <IconButton
                  onClick={recording ? stopRecording : startRecording}
                  className={recording ? styles.recording : ""}
                >
                  <MicroPhoneIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          className={styles.messageInput}
        />
      )}
      {isRecording && (
        <Box className={styles.recording_box}>
          <div className={styles.timer}>
            <span className={styles.dot} />
            <p className={styles.timer_text}> {formatTime(timer)}</p>
          </div>
          <p className={styles.cancel} onClick={handleDeleteAudio}>
            Cancel
          </p>

          <Box className={styles.recording} onClick={stopRecording}>
            <RecordingIcon />
          </Box>
        </Box>
      )}
      {isUrlValid && (
        <Box className={styles.recording_box}>
          <Box className={styles.recording_trash} onClick={handleDeleteAudio}>
            <AudioTrashIcon />
          </Box>

          <CustomAudioPlayer url={audioURL} timer={timer} />
        </Box>
      )}
      {!isUpload && (
        <Button
          disabled={sendMessageLoading}
          onClick={handleSendMessage}
          className={styles.sendButton}
        >
          <ChatSendIcon />
        </Button>
      )}
    </>
  );
};

export default ChatTools;
