import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openProfile: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setOpenProfile: (state, { payload }) => {
      state.openProfile = payload;
    },
  },
});
export const { setOpenProfile } = chatSlice.actions;

export default chatSlice.reducer;
