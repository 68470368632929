import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.module.scss";
import ChatMessage from "../Message";
import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import { RightPanelIcon } from "../../../../assets/icons";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import ChatFileUpload from "../FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { setOpenProfile } from "../../../../redux/chat/chatSlice";
import { stringSingleAvatar } from "../../../../utils";
import ChatTools from "../Tool";
import { FieldSkeleton } from "../Skeleton";
import useChats from "../../../../hooks/api/useChatsAPI";
import InfiniteScroll from "react-infinite-scroll-component";
import FieldMockUp from "../FIeldMockUp";

export const ChatField = () => {
  const messagesEndRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { id, hashId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { openProfile } = useSelector((store) => store.chat);
  const { chatData, refetch } = useOutletContext();
  const userData = useSelector((store) => store.auth);
  const {
    messages,
    chatRefetch,
    messageRefetch,
    messagesLoading,
    agentAssign,
    fetchNextPage,
    hasNextPage,
  } = useChats({
    id: id,
    hash: hashId,
  });

  const onOpenCreate = () => {
    setOpen(true);
  };

  const handleAssignAgent = () => {
    const payload = {
      chat_id: chatData?.FullUser?.ID,
      user_key: userData?.user.id,
    };
    agentAssign.mutate(payload, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const isAssignedUser = chatData?.Agent?.Id === userData?.user.id;

  const handleScrollDown = () => {
    if (messagesEndRef?.current)
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setOpen(true);
  };

  const handleRemoveFile = (fileToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const reversedMessages = useMemo(() => {
    return messages?.pages
      ?.flatMap((page) => page.payload?.messages?.Messages)
      ?.slice();
  }, [messages]);

  useEffect(() => {
    dispatch(setOpenProfile(false));
  }, [id, pathname]);

  return (
    <div className={styles.field}>
      {messagesLoading ? (
        <FieldSkeleton />
      ) : (
        <>
          <div className={styles.header}>
            <Box className={styles.content}>
              <div className={styles.img_box}>
                <Avatar
                  {...stringSingleAvatar(
                    [chatData?.Users?.[0]?.Username].join(" ")
                  )}
                />
              </div>
              <div className={styles.desc}>
                <h5 className={styles.name}>
                  {`${chatData?.Users?.[0]?.LastName} ${chatData?.Users?.[0]?.FirstName}`}
                </h5>
                <p className={styles.username}>@{chatData?.FullUser?.ID} </p>
              </div>
            </Box>
            <Box className={styles.settings}>
              <div
                className={styles.panel}
                onClick={() => {
                  dispatch(setOpenProfile(!openProfile));
                }}
              >
                <RightPanelIcon />
              </div>
            </Box>
          </div>
          {reversedMessages?.length ? (
            <div
              id="scrollableDiv"
              className={styles.messages}
              ref={messagesEndRef}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                scrollBehavior: "smooth",
              }}
            >
              <InfiniteScroll
                dataLength={reversedMessages?.length || 0}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <Box className={styles.loader}>
                    <CircularProgress />
                  </Box>
                }
                inverse={true}
                style={{ display: "flex", flexDirection: "column-reverse" }}
                scrollableTarget="scrollableDiv"
              >
                {reversedMessages?.map((message, index) => (
                  <ChatMessage
                    key={message?.ID}
                    out={message?.Out}
                    sender={message?.sender}
                    time={message?.Date}
                    content={message?.Message}
                    senderType={message?.senderType}
                    file={message?.file}
                    data={reversedMessages}
                    message={message}
                    nextMessage={reversedMessages[index + 1] || null}
                    prevMessage={reversedMessages[index - 1] || null}
                  />
                ))}
              </InfiniteScroll>
              {/* <div ref={messagesEndRef} /> */}
            </div>
          ) : (
            <FieldMockUp text="Click the Start Chat button and start sending messages." />
          )}
          <div className={styles.footer}>
            {chatData?.Agent?.Id && isAssignedUser ? (
              <ChatTools
                handleFileChange={handleFileChange}
                onUploadOpen={onOpenCreate}
                chatRefetch={chatRefetch}
                messageRefetch={messageRefetch}
                handleScrollDown={handleScrollDown}
              />
            ) : (
              <Button variant="contained" onClick={handleAssignAgent} fullWidth>
                Start Chat
              </Button>
            )}
          </div>
        </>
      )}
      <ChatFileUpload
        open={open}
        setOpen={setOpen}
        uploadedFiles={uploadedFiles}
        handleRemoveFile={handleRemoveFile}
        setUploadedFiles={setUploadedFiles}
      />
    </div>
  );
};

export default ChatField;
