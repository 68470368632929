import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as ChatSvg } from "../../../../assets/icons/chat.svg";

export const FieldMockUp = ({
  text = "Choose who you would like to message.",
}) => {
  return (
    <div className={styles.mock}>
      <div className={styles.box}>
        <ChatSvg />
        <h3>There's nothing here for now. </h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default FieldMockUp;
