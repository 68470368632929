import { Button, Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { AddSessionIcon } from "../../../../../assets/icons";
import OtpInput from "react-otp-input";
import useSessionAPI from "../../../../../hooks/api/useSessionAPI";
import toast from "react-hot-toast";

export default function SessionOtpModal({
  open,
  setOpen,
  phoneNumber,
  userId,
  setPasswordOpen,
  otp,
  setOtp,
}) {
  const { authTelegram } = useSessionAPI();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    const payload = {
      phone: phoneNumber,
      code: otp,
      password: "",
      user_id: userId,
    };

    toast.promise(authTelegram.mutateAsync(payload), {
      loading: "Checking otp...",
      success: (res) => {
        if (res.payload.code.includes("password")) {
          handleClose();
          setPasswordOpen(true);
        } else {
          handleClose();
        }
        return "Success";
      },
      error: "Error on sending phone number!",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <AddSessionIcon />
          </div>
          <div className={styles.content}>
            <h3>Add Sessions for Agents</h3>
            <p>Create you sessions agents</p>
          </div>
        </div>
        <div className={styles.body}>
          <p className={styles.confirm_text}>
            To confirm the phone, we sent a 5-digit code to {phoneNumber}
          </p>
          <OtpInput
            numInputs={5}
            value={otp}
            inputType="tel"
            renderInput={(props) => <input {...props} />}
            onChange={(e) => setOtp(e)}
            containerStyle={styles.verification_input}
          />
          <p className={styles.resend}>
            Не получили смс код? <span>Отправить повторно</span>
          </p>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button className={styles.save_button} onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
