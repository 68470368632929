import { Box, Skeleton } from "@mui/material";
import styles from "./style.module.scss";
export const ChatSkeleton = () => {
  return (
    <Box padding={"0 8px"}>
      <Skeleton variant="text" width={150} height={20} />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton variant="text" width={150} height={20} />{" "}
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton variant="text" width={150} height={20} />{" "}
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
    </Box>
  );
};

export const FieldSkeleton = () => {
  return (
    <Box
      padding={"8px"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={"100%"}
    >
      <Skeleton variant="text" width={840} height={90} />
      <Skeleton
        variant="text"
        width={400}
        height={50}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={400} height={70} />
      <Skeleton
        variant="text"
        width={400}
        height={60}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={400} height={65} />
      <Skeleton
        variant="text"
        width={400}
        height={60}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={400} height={75} />
      <Skeleton
        variant="text"
        width={400}
        height={45}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={840} height={85} />
    </Box>
  );
};
