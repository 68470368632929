import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ChatIcon from "@mui/icons-material/Chat";
export const elements = [
  {
    name: "dashboard",
    label: "Dashboard",
    to: "/dashboard",
    icon: <AnalyticsRoundedIcon />,
  },
  {
    name: "orders",
    label: "Applications",
    to: "/orders",
    icon: <DescriptionIcon />,
  },
  {
    name: "users",
    label: "Users",
    to: "/users",
    icon: <PersonIcon />,
  },
  {
    name: "website",
    label: "Website",
    to: "/website",
    icon: <DashboardCustomizeIcon />,
  },
  {
    name: "static-data",
    label: "Static Data",
    to: "/static-data",
    icon: <DataObjectIcon />,
  },
  {
    name: "chat",
    label: "Chats",
    to: "/chat",
    icon: <ChatIcon />,
  },
  // {
  //   name: "insurance",
  //   label: "Страхование",
  //   to: "/insurance",
  //   icon: <AdminPanelSettingsRoundedIcon />
  // }
];

export const agentElements = [
  {
    name: "dashboard",
    label: "Dashboard",
    to: "/dashboard",
    icon: <AnalyticsRoundedIcon />,
  },
  {
    name: "orders",
    label: "Applications",
    to: "/orders",
    icon: <DescriptionIcon />,
  },
  {
    name: "chat",
    label: "Chats",
    to: "/chat",
    icon: <ChatIcon />,
  },
];

export const editorElements = [
  {
    name: "website",
    label: "Website",
    to: "/website",
    icon: <DashboardCustomizeIcon />,
  },
];
