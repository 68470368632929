import React from "react";
import styles from "./style.module.scss";
import {
  LeadStatusBackgroundColor,
  LeadStatusText,
  LeadStatusTextColor,
} from "../../../../consts/lead";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const LeadCard = ({ data }) => {
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate(`/orders/${data?.id}/lead`);
  };
  return (
    <div className={styles.card} key={data?.id} onClick={handleRoute}>
      <div className={styles.header}>
        <span className={styles.order}>#{data?.external_order_id}</span>
        <span
          className={styles.status}
          style={{
            background: LeadStatusBackgroundColor(data?.lead?.status),
            color: LeadStatusTextColor(data?.lead?.status),
          }}
        >
          {LeadStatusText(data?.lead?.status)}
        </span>
      </div>
      <div className={styles.main}>
        {/* <span className={styles.destination}>USA - UZB</span> */}
        <span className={styles.date}>
          Date: {moment(data?.createdAt).format("DD.MM.YYYY")}
        </span>
      </div>
    </div>
  );
};

export default LeadCard;
