import { useMutation } from "react-query";
import { telegramApi } from "../../utils/telegramApi";

const useSessionAPI = (props) => {
  const initSession = useMutation((data) => {
    return telegramApi.post("telegram/session/create", data);
  });
  const joinSession = useMutation((data) =>
    telegramApi.post("telegram/session/join", data, {
      headers: {
        "USER-ID": data.user_id,
      },
    })
  );
  const authTelegram = useMutation((data) =>
    telegramApi.post("telegram/auth", data, {
      headers: {
        "USER-ID": data.user_id,
      },
    })
  );

  const deleteSession = useMutation((id) =>
    telegramApi.delete("user/" + id, {
      headers: {
        "USER-ID": id,
      },
    })
  );

  return {
    initSession,
    joinSession,
    authTelegram,
    deleteSession,
  };
};

export default useSessionAPI;
