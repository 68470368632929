import { Box, Button } from "@mui/material";
import styles from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { isUserAdmin, isUserSupervisor } from "../../../../utils";

const ChatHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isChat = pathname.includes("chat");
  const { user } = useSelector((store) => store.auth);
  const isAdmin = isUserAdmin(user.role) || isUserSupervisor(user.role);

  return (
    <div className={styles.navbar}>
      {!isChat ? (
        <Box className={styles.title_box}>
          <div onClick={() => navigate("/chat")}>
            <ArrowBack />
          </div>
          <h2>Session</h2>
        </Box>
      ) : (
        <h2>Chat</h2>
      )}
      {isChat && isAdmin ? (
        <Button
          className={styles.session_button}
          onClick={() => navigate("/session")}
        >
          Sessions
        </Button>
      ) : null}
    </div>
  );
};

export default ChatHeader;
