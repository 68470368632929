import SessionTable from "./SessionTable";
import styles from "./style.module.scss";

const SessionPage = () => {
  return (
    <div className={styles.chat}>
      <SessionTable />
    </div>
  );
};

export default SessionPage;
