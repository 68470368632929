import { Outlet, useOutletContext, useParams } from "react-router-dom";
import styles from "./style.module.scss";
import ChatSidebar from "./components/Sidebar";
import Profile from "./components/Profile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setOpenProfile } from "../../redux/chat/chatSlice";
import useChats from "../../hooks/api/useChatsAPI";
import FieldMockUp from "./components/FIeldMockUp";

const ChatPage = () => {
  const { chats, chatsIsLoading, chatsRefetch, chatsIsError } =
    useOutletContext();
  const dispatch = useDispatch();
  const { id, hashId } = useParams();

  const { chat, chatRefetch } = useChats({
    id: id,
    hash: hashId,
  });

  useEffect(() => {
    dispatch(setOpenProfile(false));
  }, []);

  return (
    <div className={styles.chat}>
      <ChatSidebar
        data={chats}
        isLoading={chatsIsLoading}
        refetch={chatsRefetch}
        isError={chatsIsError}
      />
      <Outlet
        context={{
          chatData: chat?.payload,
          refetch: chatRefetch,
        }}
      />
      <Profile chatData={chat?.payload} refetch={chatRefetch} />
      {!id && <FieldMockUp />}
    </div>
  );
};

export default ChatPage;
