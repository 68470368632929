import React, { useState } from "react";
import { Fade, IconButton, Menu, Popover } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { SmileIcon } from "../../../../assets/icons";
const EmojiPicker = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <SmileIcon />
      </IconButton>
      <Popover
        open={open}
        id="emoji-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 200 }}
        TransitionComponent={Fade}
      >
        <Picker onEmojiSelect={onSelect} data={data} />
      </Popover>
    </div>
  );
};

export default EmojiPicker;
