import { useState, useRef } from "react";
import moment from "moment";

const useTool = () => {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  const startRecording = () => {
    setAudioBlob(null);
    setAudioURL(null);
    audioChunksRef.current = [];

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(audioChunksRef.current, { type: "audio/wav" });
          const url = URL.createObjectURL(blob);
          setAudioBlob(blob);
          setAudioURL(url);
          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        setRecording(true);
        startTimer();
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    stopTimer();
  };

  const startTimer = () => {
    setTimer(0);
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 90);
    }, 100);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const handleDeleteAudio = () => {
    setAudioURL("");
    setAudioBlob(null);
    setTimer(0);
    setRecording(false);
    stopTimer();
  };

  const formatTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const centiseconds = Math.floor(duration.milliseconds() / 100);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}.${centiseconds}`;
  };

  const handleEmojiSelect = (emoji) => {
    setMessage(message + emoji.native);
  };

  return {
    recording,
    audioURL,
    message,
    timer,
    startRecording,
    stopRecording,
    handleDeleteAudio,
    formatTime,
    handleEmojiSelect,
    setMessage,
  };
};

export default useTool;
