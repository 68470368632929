export const ClockIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_419_3105)">
      <path
        d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_419_3105">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default function ArrowLeft() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12H5M5 12L12 19M5 12L12 5"
        stroke="#344054"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function NotFoundPageIcon() {
  return (
    <svg
      width="480"
      height="350"
      viewBox="0 0 480 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="240" cy="175" r="174.545" fill="#FECDCA" />
      <circle cx="56.7273" cy="44.091" r="17.4545" fill="#FEE4E2" />
      <circle cx="432" cy="275.364" r="13.0909" fill="#FEE4E2" />
      <circle cx="54.5454" cy="301.545" r="21.8182" fill="#FEE4E2" />
      <circle cx="458.182" cy="100.818" r="21.8182" fill="#FEE4E2" />
      <circle cx="416.727" cy="24.4546" r="15.2727" fill="#FEE4E2" />
      <g filter="url(#filter0_dd_938_25102)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M247.605 35.3638C211.09 35.3638 178.789 53.4077 159.132 81.0656C152.713 79.5518 146.019 78.7508 139.138 78.7508C91.2137 78.7508 52.3636 117.601 52.3636 165.525C52.3636 213.449 91.2137 252.299 139.138 252.299L139.211 252.299H355.954C355.993 252.299 356.033 252.299 356.073 252.299C398.006 252.299 432 218.305 432 176.372C432 134.438 398.006 100.444 356.073 100.444C353.094 100.444 350.156 100.616 347.267 100.95C330.645 62.3712 292.279 35.3638 247.605 35.3638Z"
          fill="#FEF3F2"
        />
        <circle
          cx="139.138"
          cy="165.525"
          r="86.774"
          fill="url(#paint0_linear_938_25102)"
        />
        <circle
          cx="247.605"
          cy="143.831"
          r="108.468"
          fill="url(#paint1_linear_938_25102)"
        />
        <circle
          cx="356.073"
          cy="176.372"
          r="75.9273"
          fill="url(#paint2_linear_938_25102)"
        />
      </g>
      <g filter="url(#filter1_b_938_25102)">
        <rect
          x="178.909"
          y="183.727"
          width="122.182"
          height="122.182"
          rx="61.0909"
          fill="#D92D20"
          fill-opacity="0.8"
        />
        <path
          d="M262.909 267.727L254 258.818M260.364 243.546C260.364 255.495 250.677 265.182 238.727 265.182C226.778 265.182 217.091 255.495 217.091 243.546C217.091 231.596 226.778 221.909 238.727 221.909C250.677 221.909 260.364 231.596 260.364 243.546Z"
          stroke="white"
          stroke-width="4.36364"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_938_25102"
          x="32.3636"
          y="35.3638"
          width="419.636"
          height="256.935"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_938_25102"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_938_25102"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_938_25102"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_938_25102"
            result="effect2_dropShadow_938_25102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_938_25102"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_938_25102"
          x="170.909"
          y="175.727"
          width="138.182"
          height="138.182"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_938_25102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_938_25102"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_938_25102"
          x1="72.5076"
          y1="108.192"
          x2="225.912"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_938_25102"
          x1="164.318"
          y1="72.1652"
          x2="356.073"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_938_25102"
          x1="297.771"
          y1="126.205"
          x2="432"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export const NotePadHeaderIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_582_9240)">
      <rect x="2" y="1" width="32" height="32" rx="6" fill="white" />
      <rect x="2.5" y="1.5" width="31" height="31" rx="5.5" stroke="#EAECF0" />
      <path
        d="M17.3333 11.6667H15.2C14.0799 11.6667 13.5198 11.6667 13.092 11.8847C12.7157 12.0764 12.4097 12.3824 12.218 12.7587C12 13.1865 12 13.7466 12 14.8667V19.8C12 20.9201 12 21.4802 12.218 21.908C12.4097 22.2843 12.7157 22.5903 13.092 22.782C13.5198 23 14.0799 23 15.2 23H20.1333C21.2534 23 21.8135 23 22.2413 22.782C22.6176 22.5903 22.9236 22.2843 23.1153 21.908C23.3333 21.4802 23.3333 20.9201 23.3333 19.8V17.6667M18.6667 20.3333H14.6667M20 17.6667H14.6667M23.4142 11.5858C24.1953 12.3668 24.1953 13.6332 23.4142 14.4142C22.6332 15.1953 21.3668 15.1953 20.5858 14.4142C19.8047 13.6332 19.8047 12.3668 20.5858 11.5858C21.3668 10.8047 22.6332 10.8047 23.4142 11.5858Z"
        stroke="#344054"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_582_9240"
        x="0"
        y="0"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_582_9240"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_582_9240"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const DotIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="4" fill="#12B76A" />
  </svg>
);
export const ArrowBack = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6673 10H3.33398M3.33398 10L8.33398 15M3.33398 10L8.33398 5"
      stroke="black"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const FilterIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
      stroke="#344054"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const RecordingIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#FEE4E2" />
    <rect
      x="2"
      y="2"
      width="32"
      height="32"
      rx="16"
      stroke="#FEF3F2"
      stroke-width="4"
    />
    <path
      d="M22.6673 16.6663V17.9997C22.6673 20.577 20.578 22.6663 18.0007 22.6663M13.334 16.6663V17.9997C13.334 20.577 15.4233 22.6663 18.0007 22.6663M18.0007 22.6663V24.6663M15.334 24.6663H20.6673M18.0007 19.9997C16.8961 19.9997 16.0007 19.1042 16.0007 17.9997V13.333C16.0007 12.2284 16.8961 11.333 18.0007 11.333C19.1052 11.333 20.0007 12.2284 20.0007 13.333V17.9997C20.0007 19.1042 19.1052 19.9997 18.0007 19.9997Z"
      stroke="#D92D20"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AudioTrashIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
      stroke="#98A2B3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const PlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 4.16699V15.8337M4.16699 10.0003H15.8337"
      stroke="#344054"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AudioPlayIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.49476C2.5 2.00917 2.5 1.76638 2.60125 1.63255C2.68945 1.51595 2.82426 1.4438 2.9702 1.43509C3.13772 1.42509 3.33973 1.55976 3.74376 1.82912L9.00154 5.3343C9.33538 5.55686 9.5023 5.66815 9.56047 5.80841C9.61133 5.93103 9.61133 6.06885 9.56047 6.19148C9.5023 6.33174 9.33538 6.44302 9.00154 6.66558L3.74376 10.1708C3.33973 10.4401 3.13772 10.5748 2.9702 10.5648C2.82426 10.5561 2.68945 10.4839 2.60125 10.3673C2.5 10.2335 2.5 9.99071 2.5 9.50513V2.49476Z"
      fill="#D92D20"
      stroke="#D92D20"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const UploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const SmileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_638_9582)">
      <path
        d="M6.66602 11.667C6.66602 11.667 7.91602 13.3337 9.99935 13.3337C12.0827 13.3337 13.3327 11.667 13.3327 11.667M12.4993 7.50033H12.5077M7.49935 7.50033H7.50768M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003ZM12.916 7.50033C12.916 7.73044 12.7295 7.91699 12.4993 7.91699C12.2692 7.91699 12.0827 7.73044 12.0827 7.50033C12.0827 7.27021 12.2692 7.08366 12.4993 7.08366C12.7295 7.08366 12.916 7.27021 12.916 7.50033ZM7.91602 7.50033C7.91602 7.73044 7.72947 7.91699 7.49935 7.91699C7.26923 7.91699 7.08268 7.73044 7.08268 7.50033C7.08268 7.27021 7.26923 7.08366 7.49935 7.08366C7.72947 7.08366 7.91602 7.27021 7.91602 7.50033Z"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_638_9582">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const AudioPauseIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.4 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5H3.4C3.96005 10.5 4.24008 10.5 4.45399 10.391C4.64215 10.2951 4.79513 10.1422 4.89101 9.95399C5 9.74008 5 9.46005 5 8.9V3.1C5 2.53995 5 2.25992 4.89101 2.04601C4.79513 1.85785 4.64215 1.70487 4.45399 1.60899C4.24008 1.5 3.96005 1.5 3.4 1.5Z"
      fill="#D92D20"
    />
    <path
      d="M8.9 1.5H8.6C8.03995 1.5 7.75992 1.5 7.54601 1.60899C7.35785 1.70487 7.20487 1.85785 7.10899 2.04601C7 2.25992 7 2.53995 7 3.1V8.9C7 9.46005 7 9.74008 7.10899 9.95399C7.20487 10.1422 7.35785 10.2951 7.54601 10.391C7.75992 10.5 8.03995 10.5 8.6 10.5H8.9C9.46005 10.5 9.74008 10.5 9.95399 10.391C10.1422 10.2951 10.2951 10.1422 10.391 9.95399C10.5 9.74008 10.5 9.46005 10.5 8.9V3.1C10.5 2.53995 10.5 2.25992 10.391 2.04601C10.2951 1.85785 10.1422 1.70487 9.95399 1.60899C9.74008 1.5 9.46005 1.5 8.9 1.5Z"
      fill="#D92D20"
    />
  </svg>
);
export const CloseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1663 5.83301L5.83301 14.1663M5.83301 5.83301L14.1663 14.1663"
      stroke="#475467"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AddSessionIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M19 21V15M16 18H22M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const GlassIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const RightPanelIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 2.5V17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z"
      stroke="#344054"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ChatSendIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const FaceSmileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_638_9375)">
      <path
        d="M6.66602 11.667C6.66602 11.667 7.91602 13.3337 9.99935 13.3337C12.0827 13.3337 13.3327 11.667 13.3327 11.667M12.4993 7.50033H12.5077M7.49935 7.50033H7.50768M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003ZM12.916 7.50033C12.916 7.73044 12.7295 7.91699 12.4993 7.91699C12.2692 7.91699 12.0827 7.73044 12.0827 7.50033C12.0827 7.27021 12.2692 7.08366 12.4993 7.08366C12.7295 7.08366 12.916 7.27021 12.916 7.50033ZM7.91602 7.50033C7.91602 7.73044 7.72947 7.91699 7.49935 7.91699C7.26923 7.91699 7.08268 7.73044 7.08268 7.50033C7.08268 7.27021 7.26923 7.08366 7.49935 7.08366C7.72947 7.08366 7.91602 7.27021 7.91602 7.50033Z"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_638_9375">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const MicroPhoneIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8327 8.33366V10.0003C15.8327 13.222 13.221 15.8337 9.99935 15.8337M4.16602 8.33366V10.0003C4.16602 13.222 6.77769 15.8337 9.99935 15.8337M9.99935 15.8337V18.3337M6.66602 18.3337H13.3327M9.99935 12.5003C8.61864 12.5003 7.49935 11.381 7.49935 10.0003V4.16699C7.49935 2.78628 8.61864 1.66699 9.99935 1.66699C11.3801 1.66699 12.4993 2.78628 12.4993 4.16699V10.0003C12.4993 11.381 11.3801 12.5003 9.99935 12.5003Z"
      stroke="#98A2B3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AttachmentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6261 9.08247L10.1131 16.5955C8.40458 18.304 5.63448 18.304 3.92594 16.5955C2.2174 14.8869 2.2174 12.1168 3.92594 10.4083L11.4389 2.89529C12.578 1.75626 14.4247 1.75626 15.5637 2.89529C16.7028 4.03432 16.7028 5.88105 15.5637 7.02008L8.34536 14.2385C7.77584 14.808 6.85248 14.808 6.28296 14.2385C5.71345 13.6689 5.71345 12.7456 6.28296 12.1761L12.6175 5.84157"
      stroke="#98A2B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ChatIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 10C18 14.4183 14.4183 18 10 18C8.93587 18 7.92027 17.7922 6.99155 17.4151C6.8138 17.3429 6.72493 17.3068 6.65308 17.2907C6.58281 17.2749 6.5308 17.2692 6.45878 17.2692C6.38516 17.2691 6.30497 17.2825 6.14458 17.3092L2.98201 17.8363C2.65083 17.8915 2.48524 17.9191 2.3655 17.8678C2.2607 17.8228 2.17718 17.7393 2.13223 17.6345C2.08087 17.5148 2.10847 17.3492 2.16367 17.018L2.69076 13.8554C2.71749 13.695 2.73086 13.6148 2.73085 13.5412C2.73084 13.4692 2.72508 13.4172 2.70933 13.3469C2.69323 13.2751 2.65713 13.1862 2.58494 13.0085C2.20777 12.0797 2 11.0641 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
      fill="#828282"
    />
    <path
      d="M18 10C18 14.4183 14.4183 18 10 18C8.93587 18 7.92027 17.7922 6.99155 17.4151C6.8138 17.3429 6.72493 17.3068 6.65308 17.2907C6.58281 17.2749 6.5308 17.2692 6.45878 17.2692C6.38516 17.2691 6.30497 17.2825 6.14458 17.3092L2.98201 17.8363C2.65083 17.8915 2.48524 17.9191 2.3655 17.8678C2.2607 17.8228 2.17718 17.7393 2.13223 17.6345C2.08087 17.5148 2.10847 17.3492 2.16367 17.018L2.69076 13.8554C2.71749 13.695 2.73086 13.6148 2.73085 13.5412C2.73084 13.4692 2.72508 13.4172 2.70933 13.3469C2.69323 13.2751 2.65713 13.1862 2.58494 13.0085C2.20777 12.0797 2 11.0641 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
      stroke="white"
    />
    <circle
      cx="6"
      cy="10"
      r="0.9"
      fill="#D9D9D9"
      stroke="#828282"
      stroke-width="0.2"
    />
    <circle
      cx="10"
      cy="10"
      r="0.9"
      fill="#D9D9D9"
      stroke="#828282"
      stroke-width="0.2"
    />
    <circle
      cx="14"
      cy="10"
      r="0.9"
      fill="#D9D9D9"
      stroke="#828282"
      stroke-width="0.2"
    />
  </svg>
);
