import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { telegramApi } from "../../utils/telegramApi";

const useChats = (props) => {
  const { data, isLoading, refetch, isError } = useQuery(
    ["GET_CHATS"],
    () => telegramApi.get("telegram/chats", {}),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 2000,
      onSuccess: props?.onChatSuccess,
    }
  );

  const { data: chat, refetch: chatRefetch } = useQuery(
    ["GET_CHAT", props?.id, props?.hash],
    () => telegramApi.get(`telegram/chat/${props?.id}/${props?.hash}`),
    {
      enabled: !!props?.id,
      refetchInterval: 2000,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: messages,
    fetchNextPage,
    hasNextPage,
    isLoading: messagesLoading,
    isFetching,
    refetch: messageRefetch,
  } = useInfiniteQuery(
    ["GET_MESSAGES", props?.id, props?.hash],
    ({ pageParam = 0 }) => {
      return telegramApi.get(`telegram/messages/${props?.id}/${props?.hash}`, {
        params: {
          offset_id: pageParam,
          limit: 100,
        },
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const lastMessage = lastPage?.payload?.messages?.Messages?.slice(-1)[0];
        return lastMessage?.ID;
      },
      enabled: !!props?.id,
      refetchInterval: 2000,
      refetchOnWindowFocus: false,
    }
  );

  const agentAssign = useMutation((data) =>
    telegramApi.post("chat/assign", data)
  );

  const { mutate: sendMessage, isLoading: sendMessageLoading } = useMutation(
    (data) =>
      telegramApi.post(
        `telegram/message/sent/${data?.id}/${data?.hashId}`,
        data
      )
  );

  return {
    data,
    isLoading,
    chat,
    agentAssign,
    messages,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
    chatRefetch,
    messageRefetch,
    isError,
    sendMessage,
    messagesLoading,
    sendMessageLoading,
  };
};

export default useChats;
