import { Avatar, Box, InputAdornment, TextField } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlassIcon } from "../../../../assets/icons";
import styles from "./style.module.scss";
import classNames from "classnames";
import { ChatSkeleton } from "../Skeleton/index.jsx";
import { stringSingleAvatar } from "../../../../utils/index.js";

const ChatSidebar = ({ data, isLoading, isError, refetch }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const chats = useMemo(() => {
    if (data?.payload?.chats?.length) {
      const groupedChats = {};

      data.payload.chats.forEach((item) => {
        const formattedDate = moment(item.LastMessage.Date).format(
          "MMMM DD, YYYY"
        );
        const time = moment(item.LastMessage.Date).format("hh:mm A");

        const chatItem = {
          name: `${item.Chat.LastName} ${item.Chat.FirstName}`,
          platform: "telegram.com",
          time: time,
          unread: item?.Dialog?.UnreadCount,
          avatar: item?.Agent.Photo || "/chat_ava.jpg",
          assigned: item?.IsAssigned && item?.Agent?.LastName,
          id: `${item?.Agent.Id}-${item?.Chat.ID}`,
          hash: item?.Chat.AccessHashStr,
          chat_id: item?.Chat.ID,
          last_message: item?.LastMessage.Message?.replace(/\n/g, " "),
          assigned_agent: `${item?.Agent?.LastName} ${item?.Agent?.FirstName}`,
        };

        if (!groupedChats[formattedDate]) {
          groupedChats[formattedDate] = [];
        }

        groupedChats[formattedDate].push(chatItem);
      });

      return Object.keys(groupedChats)
        .sort((a, b) =>
          moment(b, "MMMM DD, YYYY").diff(moment(a, "MMMM DD, YYYY"))
        )
        .map((date) => ({
          date: date,
          users: groupedChats[date],
        }));
    }

    return [];
  }, [data]);

  return (
    <div className={styles.sidebar}>
      <div className={styles.search}>
        <TextField
          style={{
            background: "#fff",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GlassIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {isLoading ? (
        <ChatSkeleton />
      ) : (
        <>
          {chats?.map((group, index) => (
            <div key={group.id} className={styles.date_group}>
              <div className={styles.date}>{group.date}</div>
              <div className={styles.group_box}>
                {group.users.map((user, userIndex) => {
                  const isActive = user.id === id;
                  return (
                    <Box
                      key={userIndex}
                      className={classNames(styles.user, {
                        [styles.active]: isActive,
                      })}
                      onClick={() =>
                        navigate(`/chat/${user.chat_id}/${user.hash}`)
                      }
                    >
                      <div className={styles.userInfo}>
                        {/* <img
                          src={user.avatar}
                          alt="user"
                          className={styles.avatar}
                        /> */}
                        <Avatar
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                          {...stringSingleAvatar([user?.name].join(" "))}
                        />
                        <div className={styles.details}>
                          <div className={styles.userName}>{user.name}</div>
                          <div className={styles.platform}>
                            {user.last_message}
                          </div>
                          {user?.status && (
                            <div className={styles.status}>
                              <span className={styles.status_text}>
                                {user.status}
                              </span>
                            </div>
                          )}
                          {!user?.assigned && (
                            <div className={styles.unassigned}>
                              <span className={styles.dot} />
                              <p className={styles.unassigned_text}>
                                Unassigned
                              </p>
                            </div>
                          )}
                          {user?.assigned && (
                            <div className={styles.assigned}>
                              <span className={styles.dot} />
                              <p className={styles.assigned_text}>
                                {user?.assigned_agent}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className={styles.unreadTime}>
                          <div className={styles.time}>{user.time}</div>
                          {user.unread ? (
                            <div className={styles.unread}>{user.unread}</div>
                          ) : null}
                        </div>
                      </div>
                    </Box>
                  );
                })}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ChatSidebar;
