import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import styles from "./style.module.scss";
import ChatHeader from "../../pages/Chat/components/Header";
import useLayout from "./useLayout";

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  const Renderer =
    pathname.includes("/chat") || pathname.includes("/session")
      ? ChatHeader
      : Navbar;

  const { chats, chatsIsLoading, chatsRefetch, chatsIsError } = useLayout();

  return (
    <div className={styles.container}>
      <Sidebar />

      <div className={styles.wrapper}>
        <Renderer />
        <div className={styles.content}>
          <Outlet
            context={{
              chats,
              chatsIsLoading,
              chatsRefetch,
              chatsIsError,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
